import React from 'react';
import { Link } from 'gatsby';
import LandingImageOne from '../components/images/LandingImageTwo';
import Layout from '../layout';
import SpeedTest from '../../static/images/speedtest.svg';
import HomeScreen from '../components/images/ScreenLink';
import LandingImageThree from '../components/images/LandingImageThree';

const Landing = () => (
  <Layout>
    <div className="col-md-9 mx-auto">
      <div className="row align-content-center min-vh-80 justify-content-start">
        <div className="col-md-6 p-4 align-self-center">
          <h1 className="text-left display-4 font-weight-bold m-0 text-primary p-0">Less</h1>
          <h4 className="text-left col-md-8 text-muted px-0 py-3">
            A highly flexible, Gatsby JS, Bootstrap Blogging Theme
            <span className="text-neutralDark font-weight-bold"> With Netlify CMS</span>
          </h4>
          <button className="btn btn-lg rounded-0 btn-primary">Buy now</button>
        </div>
        <div className="col-md-6 p-0 mx-auto shadow">
          <LandingImageOne />
        </div>
      </div>
      <div className="row py-2 align-content-center m-0">
        <div className="col-md-6">
          <h2 className="text-center text-muted text-muted p-4">Google Page Speed Test</h2>
          <img src={SpeedTest} className="mr-3" alt="google page speed test" />
        </div>
        <div className="col-md-6">
          <h2 className="text-center text-muted text-muted p-4">Core Theme Features</h2>
          <div className="row justify-content-center">
            <div className="col-md-3 rounded-0 p-3 m-1 border">
              <div className="media">
                <div className="media-body">
                  <h5 className="mt-0 text-center">Bootstrap 4 SCSS</h5>
                </div>
              </div>
            </div>
            <div className="col-md-3 rounded-0 p-3 m-1 border">
              <div className="media">
                <div className="media-body">
                  <h5 className="mt-0 text-center">Disqus Commenting System</h5>
                </div>
              </div>
            </div>
            <div className="col-md-3 rounded-0 p-3 m-1 border">
              <div className="media">
                <div className="media-body">
                  <h5 className="mt-0 text-center">Fontawesome Icons</h5>
                </div>
              </div>
            </div>
            <div className="col-md-3 rounded-0 p-3 m-1 border">
              <div className="media">
                <div className="media-body">
                  <h5 className="mt-0 text-center">Progressive Web Application functionality</h5>
                </div>
              </div>
            </div>
            <div className="col-md-3 rounded-0 p-3 m-1 border">
              <div className="media">
                <div className="media-body">
                  <h5 className="mt-0 text-center">React js Component Based</h5>
                </div>
              </div>
            </div>
            <div className="col-md-3 rounded-0 p-3 m-1 border">
              <div className="media">
                <div className="media-body">
                  <h5 className="mt-0 text-center">Form notifications with Netlify hosting</h5>
                </div>
              </div>
            </div>
            <div className="col-md-3 rounded-0 p-3 m-1 border">
              <div className="media">
                <div className="media-body">
                  <h5 className="mt-0 text-center">MailChimp subscriptions</h5>
                </div>
              </div>
            </div>
            <div className="col-md-3 rounded-0 p-3 m-1 border">
              <div className="media">
                <div className="media-body">
                  <h5 className="mt-0 text-center">SEO </h5>
                </div>
              </div>
            </div>
            <div className="col-md-3 rounded-0 p-3 m-1 border">
              <div className="media">
                <div className="media-body">
                  <h5 className="mt-0 text-center">Free hosting with Netlify</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <h2 className="text-center text-muted text-muted p-4">Less Journal Theme</h2>
        <div className="card border-0 bg-dark text-white">
          <HomeScreen />
          <div className="card-img-overlay">
            <div className="row justify-content-center h-100 ">
              <div className=" mx-auto align-self-center">
                <Link className="btn btn-primary rounded-0 shadow btn-lg">View Main Theme </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2 className="text-center text-muted text-muted py-5">A few more benefits</h2>
      <div className="row p-4 min-vh-60">
        <div className="col-md-6">
          <LandingImageThree />
        </div>
        <div className="col-md-6 align-self-center">
          <div className="media p-2">
            <i className="fa p-2 fa-arrow-right text-primary fa-lg" />
            <div className="media-body">
              <h5 className="py-1 mt-0">Powerful Image Processing</h5>
              Experience the best Image processing and optimization technology on the web with Gatsby Image
            </div>
          </div>
          <div className="p-2 media">
            <i className="fa p-2 fa-arrow-right text-primary fa-lg" />
            <div className="media-body">
              <h5 className=" py-1 mt-0">Social Media Sharing</h5>
              Validate your post cards, share your articles on social media easily
            </div>
          </div>
          <div className="media p-2">
            <i className="fa p-2 fa-arrow-right text-primary fa-lg" />
            <div className="py-1 media-body">
              <h5 className=" py-1 mt-0">Mailchimp Subscriptions</h5>
              Create your mail lists easily, setup newsletters, allow people to subscribe to your blog posts.
            </div>
          </div>
        </div>
      </div>
      <div className="container text-center py-5">
        <div className="row">
          <div className="col align-self-center mx-auto">
            <button className="btn btn-primary rounded-0 text-center btn-lg">Buy Less Journal</button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default Landing;
